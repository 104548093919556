import React, { useMemo } from "react";
import styled from "styled-components";
import { HeaderUnderline } from "../../../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { muiTheme } from "../../../../../lib/Theme";

export interface ContactsAndInformationProps {
  className?: string;
}

export const ContactSectionTitle = styled.h2`
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const ContactSection = styled.div`
  margin-bottom: 20px;

  p {
    color: ${props => props.theme.color.gray};
    margin-bottom: 7px;
  }

  .label {
    margin-right: 20px;
    min-width: 100px;
    display: inline-flex;

    ${muiTheme.breakpoints.down("sm")} {
      margin-right: 5px;
      min-width: 80px;
    }
  }
`;

const ContactLink = styled.a`
  cursor: pointer;
  transition: all 230ms ease-in-out;
  &:hover {
    color: ${props => props.theme.color.primary};
  }
`;

const ContactsAndInformationInner = (props: ContactsAndInformationProps) => {
  const { t } = useTranslation("common");
  const officeAddress = useMemo(
    () => t("contactInformation.officeAddress", { returnObjects: true }),
    []
  );
  const invoicingAddress = useMemo(
    () => t("contactInformation.invoicingAddress", { returnObjects: true }),
    []
  );
  return (
    <article className={props.className}>
      <HeaderUnderline>{t("contactInformation.title")}</HeaderUnderline>
      <ContactSection>
        <ContactSectionTitle>Kancelária</ContactSectionTitle>
        <p>
          <span className="label">Tel.:</span>
          <ContactLink href="tel:+421 910 442 286">
            +421 910 442 286
          </ContactLink>
        </p>
        <p>
          <span className="label">E-mail.:</span>
          <ContactLink href="mailto:info@altro.sk">info@altro.sk</ContactLink>
        </p>
      </ContactSection>

      <ContactSection>
        <ContactSectionTitle>Konatelia</ContactSectionTitle>
        <p>ALBERT Peter</p>
        <p>
          <span className="label">Tel.:</span>
          <ContactLink href="tel:+421 904 666 444">
            +421 904 666 444
          </ContactLink>
        </p>
        <p>
          <span className="label">E-mail.:</span>
          <ContactLink href="mailto:albert@altro.sk">
            albert@altro.sk
          </ContactLink>
        </p>
        <p
          style={{
            marginTop: 20
          }}
        >
          TROJČÁKOVÁ Radoslava
        </p>
        <p>
          <span className="label">Tel.:</span>
          <ContactLink href="tel:+421 903 988 669">
            +421 903 988 669
          </ContactLink>
        </p>
        <p>
          <span className="label">E-mail.:</span>
          <ContactLink href="mailto:trojcakova@altro.sk">
            trojcakova@altro.sk
          </ContactLink>
        </p>
      </ContactSection>

      <ContactSection>
        <ContactSectionTitle>Ekonomické oddelenie</ContactSectionTitle>
        <p>ALBERTOVÁ Marianna</p>
        <p>
          <span className="label">Tel.:</span>
          <ContactLink href="tel:+421 910 442 286">
            +421 910 442 286
          </ContactLink>
        </p>
        <p>
          <span className="label">E-mail.:</span>
          <ContactLink href="mailto:ekonomka@altro.sk">
            ekonomka@altro.sk
          </ContactLink>
        </p>
      </ContactSection>

      <ContactSection>
        <ContactSectionTitle>ADRESA FAKTURAČNÁ</ContactSectionTitle>
        <p>
          Jankolova 9, 851 04, Bratislava - Petržalka
          <br />
          Slovenská republika
        </p>

        <p>
          <span className="label">IČO:</span>
          <span className="value">53087071</span>
        </p>
        <p>
          <span className="label">DIČ:</span>
          <span className="value">2121256973</span>
        </p>
      </ContactSection>
    </article>
  );
};

export const ContactsAndInformation = styled(ContactsAndInformationInner)``;
