import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { ContactBreadcrumb } from "../components/kontakt/ContactBreadcrumb/ContactBreadcrumb";
import { ContactAndMapSection } from "../components/kontakt/ContactAndMapSection/ContactAndMapSection";
import { ContactFormSection } from "../components/Shared/ContactFormSection/ContactFormSection";

const ContactPage = () => (
  <Layout>
    <ContactBreadcrumb />
    <ContactAndMapSection />
    <ContactFormSection />
  </Layout>
);

export default ContactPage;
