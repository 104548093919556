import React from "react";
import styled from "styled-components";
import { HeaderUnderline } from "../../../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { GoogleMap } from "../../../../Shared/GoogleMap/GoogleMap";
import { Box } from "@material-ui/core";
import {
  ContactSection,
  ContactSectionTitle
} from "../ContactsAndInformation/ContactsAndInformation";

export interface OfficeLocationProps {
  className?: string;
}

const MapAdditionalText = styled.p`
  color: ${props => props.theme.color.gray};
  margin-top: 10px;
  font-size: 14px;
`;

const OfficeLocationInner = (props: OfficeLocationProps) => {
  const { t } = useTranslation("common");
  return (
    <article className={props.className}>
      <HeaderUnderline>KDE nás nájdete</HeaderUnderline>
      <GoogleMap />
      <MapAdditionalText>
        Kancelária sa nachádza na prízemí BC Technopol. Parkovanie je možné v
        objekte.
      </MapAdditionalText>
      <div
        style={{
          marginTop: 20
        }}
      >
        <HeaderUnderline>ADRESA KANCELÁRIE</HeaderUnderline>
        <ContactSection>
          <p>
            Kutlikova 17, 851 02, Bratislava - Petržalka (Technopol) <br />{" "}
            Slovenská republika
          </p>
          <ContactSectionTitle>Stránkové hodiny</ContactSectionTitle>
          <p>Pondelok: 09:00 – 13:00</p>
          <p>Streda: 12:00 – 17:00</p>
        </ContactSection>
      </div>
    </article>
  );
};

export const OfficeLocation = styled(OfficeLocationInner)`
  ${GoogleMap} {
    height: 350px;
  }
`;
