import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PageBreadcrumb } from "../../Shared/PageBreadcrumb/PageBreadcrumb";

export interface ContactBreadcrumbProps {
  className?: string;
}

const ContactBreadcrumbInner = (props: ContactBreadcrumbProps) => {
  const { t } = useTranslation("common");
  return <PageBreadcrumb breadCrumbText={t("routes.contact")} />;
};

export const ContactBreadcrumb = ContactBreadcrumbInner;
