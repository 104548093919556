import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { Container, Grid } from "@material-ui/core";
import { ContactsAndInformation } from "./components/ContactsAndInformation/ContactsAndInformation";
import { OfficeLocation } from "./components/OfficeLocation/OfficeLocation";

export interface ContactAndMapSectionProps {
  className?: string;
}

const ContactAndMapSectionInner = (props: ContactAndMapSectionProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <ContactsAndInformation />
          </Grid>
          <Grid item xs={12} md={6}>
            <OfficeLocation />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const ContactAndMapSection = styled(ContactAndMapSectionInner)``;
