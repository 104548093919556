import React from "react";
import styled, { StyledComponent } from "styled-components";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa";

export type GoogleMapProps = React.HTMLAttributes<HTMLElement>;

const isClient = typeof window !== "undefined";

const GoogleMapInner: React.FC<GoogleMapProps> = (props: GoogleMapProps) => {
  const center = [48.111297, 17.110868];
  return (
    <section className={props.className}>
      {isClient && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBMePsrPSWpSEipq6olgSTQ7HynliRaRv0" }}
          defaultCenter={center}
          defaultZoom={16}
        >
          <FaMapMarkerAlt className="marker" lat={center[0]} lng={center[1]} />
        </GoogleMapReact>
      )}
    </section>
  );
};

export const GoogleMap = styled(GoogleMapInner)<GoogleMapProps>`
  display: flex;

  .marker {
    fill: ${props => props.theme.color.primary};
    font-size: 4rem;
    transform: translate(-50%, -100%);
  }
`;
